


























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class MainInfo extends Vue {
  @Prop({
    required: true
  })
  public place!: any;

  @Prop()
  public placeMenu!: any;

  public infoState: boolean = false;

  public NumberFormat(num) {
    if (!num && num !== 0) return 0;
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  public getStar(num) {
    return (num / 20).toFixed(1);
  }

  @Watch('infoState')
  public onOff() {
    if (this.infoState === false) {
      document.querySelector('html' as any).classList.remove('open-modal');
    } else {
      document.querySelector('html' as any).classList.add('open-modal');
    }
  }
}
